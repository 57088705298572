import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../environments/environment';
import { timer } from 'rxjs';
import { AuthenticationService } from '../_services';

@Injectable()
export class AuthSuperadminGuard implements CanActivate {

    constructor(private router: Router, private auth: AuthenticationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

       
        var token;

        // token = of(localStorage.getItem(`${environment.appName}` + '_user'));

        if (localStorage.getItem(`${environment.appName}` + '_user')) {
            return true;
            
        } else {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            localStorage.setItem(`${environment.appName}` + '_user', '')
            return false;
        }

    }


}


