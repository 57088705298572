import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
@Injectable()
export class HomeService {

    constructor(private http: HttpClient) { }
    ccevenue(
        amount: any,
        // membership_type: any,
        billing_name:any,
        // checkArray: any,
        billing_email:any,
        // MembershipPeriod: any,
        billing_phone:any,
        billing_address:any,
        orderid: any,
        tid:any,
    ) {

        var formData = {
            amount: amount,
            billing_name: billing_name,
            // checkArray: checkArray,
            billing_email:billing_email,
            billing_phone: billing_phone,
            billing_address:billing_address,
            orderid: orderid,
            merchant_param1 : "marena",
            tid:tid
        }

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/ccavenue`, formData).pipe(map(user => {
            return user;
        }));
    }

    //for swimming 
    ccevenue1(
        amount: any,
        // membership_type: any,
        billing_name:any,
        // checkArray: any,
        billing_email:any,
        // MembershipPeriod: any,
        billing_phone:any,
        billing_address:any,
        orderid: any,
        tid:any
    ) {

        var formData = {
            amount: amount,
            billing_name: billing_name,
            // checkArray: checkArray,
            billing_email:billing_email,
            billing_phone: billing_phone,
            billing_address:billing_address,
            orderid: orderid,
            merchant_param1 : "swimming",
            tid:tid
        }

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/ccavenue1`, formData).pipe(map(user => {
            return user;
        }));
    }

    //for ad-hoc

    ccevenuequickpay(
        amount: any,
        billing_name:any,
        // checkArray: any,
        billing_email:any,
        // MembershipPeriod: any,
        billing_phone:any,
        billing_address:any,
        orderid: any
    ) {

        var formData = {
            amount: amount,
            billing_name: billing_name,
            // checkArray: checkArray,
            billing_email:billing_email,
            billing_phone: billing_phone,
            billing_address:billing_address,
            orderid: orderid,
            merchant_param1 : "quickpay"
        }

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/ccavenue2`, formData).pipe(map(user => {
            return user;
        }));
    }

    getDashboardDetails(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/dashboard`).pipe(map(user => user));
    }



}
