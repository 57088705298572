import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { HttpResponse } from "@angular/common/http";
@Injectable()
export class AuthenticationService {
  constructor(private http: HttpClient) {}
  register(
    name: string,
    email: string,
    phone_number: string,
    gender: string,
    dob: string,
    address: string,
    employee_code: any,
    institution_name: any
  ) {
    const formData = {
      loginType: 3,
      name: name,
      email: email,
      phone_number: phone_number,
      gender: gender,
      dob: dob,
      address: address,
      enrollment_Number: employee_code,
      institution: institution_name,
    };
    console.log(formData);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/register`,
        formData
      )
      .pipe(
        map((user) => {
          console.log("user", JSON.stringify(user.data));
          localStorage.setItem(`${environment.appName}` + 'user_info',  JSON.stringify(user.data));

          // if (user.status.code == 0) {
          //     localStorage.setItem(`${environment.appName}` + '_adminName', user.data.name);
          //     localStorage.setItem(`${environment.appName}` + '_adminId', user.data.reg_ID);
          //     localStorage.setItem(`${environment.appName}` + '_role', user.data.roleId);
          //     localStorage.setItem(`${environment.appName}` + '_status', user.data.status);
          //     localStorage.setItem(`${environment.appName}` + '_domain', '');
          // }

          return user;
        })
      );
  }

  vehicleregister(
    name: string,
    email: string,
    phone_number: string,
    gender: string,
    dob: string,
    address: string,
    employee_code: any,
    institution_name: any
  ) {
    const formData = {
      loginType: 3,
      name: name,
      email: email,
      phone_number: phone_number,
      gender: gender,
      dob: dob,
      address: address,
      enrollment_Number: employee_code,
      institution: institution_name,
    };
    console.log(formData);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/vehicle-reg/register`,
        formData
      )
      .pipe(
        map((user) => {
          console.log("user", JSON.stringify(user.data));
          localStorage.setItem(`${environment.appName}` + 'user_info',  JSON.stringify(user.data));

          // if (user.status.code == 0) {
          //     localStorage.setItem(`${environment.appName}` + '_adminName', user.data.name);
          //     localStorage.setItem(`${environment.appName}` + '_adminId', user.data.reg_ID);
          //     localStorage.setItem(`${environment.appName}` + '_role', user.data.roleId);
          //     localStorage.setItem(`${environment.appName}` + '_status', user.data.status);
          //     localStorage.setItem(`${environment.appName}` + '_domain', '');
          // }

          return user;
        })
      );
  }

  localLoginWithEmail(type: any, name: string, email: string) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/register`,
        { loginType: type, name: name, email: email }
      )
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          // return false;
          if (user.status.code == 0) {
            localStorage.setItem(
              `${environment.appName}` + "_user_obj",
              JSON.stringify(user.data.user)
            );
            // localStorage.setItem(`${environment.appName}` + '_user_room', JSON.stringify(user.room));
            // localStorage.setItem(`${environment.appName}` + '_user_room_details',user.user.room_details );
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(
              `${environment.appName}` + "_user",
              user.data.access_token
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminName",
              user.data.user.name
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminId",
              user.data.user._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_userId",
              user.data.user._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_role",
              user.data.user.roleId
            );
            localStorage.setItem(
              `${environment.appName}` + "_status",
              user.data.user.status
            );
            // localStorage.setItem(`${environment.appName}` + '_domain', user.user.shop ? user.user.shop.domain : '');
          }
          console.log(user);

          return user;
        })
      );
  }

  conferencelogin(mobileno: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/bangalore-conference/sendotp`,
        { mobile: mobileno }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  conferenceverifyotp(mobileno: any, otp: any) {
    console.log("user");
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/bangalore-conference/verifyotp`,
        { mobile: mobileno, otp: otp }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            console.log(user);
            localStorage.setItem(
              `${environment.appName}` + "_token",
              user.data.token
            );
            localStorage.setItem(
              `${environment.appName}` + "_mobile",
              user.data.user.mobile
            );
          }
          return user;
        })
      );
  }

  tmapailogin(mobileno: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/tmaPai/login`,
        { mobile: mobileno }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  agilelogin(mobileno: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/AgileAndDevOps/login`,
        { mobile: mobileno }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  conferenceblrlogin(mobileno: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/conference2024/login`,
        { mobile: mobileno }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  daycarelogin(mobileno: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/MAGU/login`,
        { mobile: mobileno }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }


  tmapaiverifyotp(mobileno: any, otp: any) {
    console.log("user");
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/tmaPai/verify-otp`,
        { mobile: mobileno, otp: otp }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            console.log(user);
            localStorage.setItem(
              `${environment.appName}` + "tma_token",
              user.data.token
            );
            localStorage.setItem(
              `${environment.appName}` + "_mobile",
              user.data.user.mobile
            );
          }
          return user;
        })
      );
  }

  agileverifyotp(mobileno: any, otp: any) {
    console.log("user");
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/AgileAndDevOps/verify-otp`,
        { mobile: mobileno, otp: otp }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            console.log(user);
            localStorage.removeItem(`${environment.appName}` + "conference_token");
            // localStorage.removeItem(`${environment.appName}` + "agile_token");
            localStorage.removeItem(`${environment.appName}` + "hostels_token");
            localStorage.setItem(
              `${environment.appName}` + "agile_token",
              user.data.token
            );
            localStorage.setItem(
              `${environment.appName}` + "_mobile",
              user.data.user.mobile
            );
          }
          return user;
        })
      );
  }

  conferenceblrverifyotp(mobileno: any, otp: any) {
    console.log("user");
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/conference2024/verify-otp`,
        { mobile: mobileno, otp: otp }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            console.log(user);
            // localStorage.removeItem(`${environment.appName}` + "conference_token");
            localStorage.removeItem(`${environment.appName}` + "agile_token");
            localStorage.removeItem(`${environment.appName}` + "hostels_token");
            localStorage.setItem(
              `${environment.appName}` + "conference_token",
              user.data.token
            );
            localStorage.setItem(
              `${environment.appName}` + "_mobile",
              user.data.user.mobile
            );
          }
          return user;
        })
      );
  }

  daycareverifyotp(mobileno: any, otp: any) {
    console.log("user");
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/MAGU/verify-otp`,
        { mobile: mobileno, otp: otp }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            console.log(user);
            // localStorage.removeItem(`${environment.appName}` + "conference_token");
            localStorage.removeItem(`${environment.appName}` + "agile_token");
            localStorage.removeItem(`${environment.appName}` + "hostels_token");
            localStorage.removeItem(`${environment.appName}` + "conference_token");
            localStorage.setItem(
              `${environment.appName}` + "daycare_token",
              user.data.token
            );
            localStorage.setItem(
              `${environment.appName}` + "_mobile",
              user.data.user.mobile
            );
          }
          return user;
        })
      );
  }

  iqacverifyotp(mobileno: any, otp: any) {
    console.log("user");
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/iqac/verify-otp`,
        { mobile: mobileno, otp: otp }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            console.log(user);
            localStorage.setItem(
              `${environment.appName}` + "iqac_token",
              user.data.token
            );
            localStorage.setItem(
              `${environment.appName}` + "_mobile",
              user.data.user.mobile
            );
          }
          return user;
        })
      );
  }

  finacilaverifyotp(mobileno: any, otp: any) {
    console.log("user");
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/TDWFE/verify-otp`,
        { mobile: mobileno, otp: otp }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            console.log(user);
            localStorage.setItem(
              `${environment.appName}` + "finacial_token",
              user.data.token
            );
            localStorage.setItem(
              `${environment.appName}` + "_mobile",
              user.data.user.mobile
            );
          }
          return user;
        })
      );
  }

  lawverifyotp(mobileno: any, otp: any) {
    console.log("user");
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/ConstructionLawSchoolBlr/verify-otp`,
        { mobile: mobileno, otp: otp }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            console.log(user);
            localStorage.setItem(
              `${environment.appName}` + "law_token",
              user.data.token
            );
            localStorage.setItem(
              `${environment.appName}` + "_mobile",
              user.data.user.mobile
            );
          }
          return user;
        })
      );
  }

  adhikshanaverifyotp(mobileno: any, otp: any) {
    console.log("user");
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/ansysLumerical/verify-otp

        `,
        { mobile: mobileno, otp: otp }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            console.log(user);
            localStorage.setItem(
              `${environment.appName}` + "faculty_token",
              user.data.token
            );
            localStorage.setItem(
              `${environment.appName}` + "_mobile",
              user.data.user.mobile
            );
          }
          return user;
        })
      );
  }

  reflektaverifyotp(mobileno: any, otp: any) {
    console.log("user");
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/reflekta/verify-otp

        `,
        { mobile: mobileno, otp: otp }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            localStorage.setItem(
              `${environment.appName}` + "refleckta_token",
              user.data.token
            );
            localStorage.setItem(
              `${environment.appName}` + "_mobile",
              user.data.user.mobile
            );
          }
          return user;
        })
      );
  }

  aleyverifyotp(mobileno: any, otp: any) {
    console.log("user");
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/Aley/verify-otp

        `,
        { mobile: mobileno, otp: otp }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            localStorage.setItem(
              `${environment.appName}` + "aley_token",
              user.data.token
            );
            localStorage.setItem(
              `${environment.appName}` + "_mobile",
              user.data.user.mobile
            );
          }
          return user;
        })
      );
  }

  bazaarverifyotp(mobileno: any, otp: any) {
    console.log("user");
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/BazaarMic/verify-otp

        `,
        { mobile: mobileno, otp: otp }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            localStorage.setItem(
              `${environment.appName}` + "bazaar_token",
              user.data.token
            );
            localStorage.setItem(
              `${environment.appName}` + "_mobile",
              user.data.user.mobile
            );
          }
          return user;
        })
      );
  }

  cricketverifyotp(mobileno: any, otp: any) {
    console.log("user");
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/StaffCricket2025/verify-otp

        `,
        { mobile: mobileno, otp: otp }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            localStorage.setItem(
              `${environment.appName}` + "cricket_token",
              user.data.token
            );
            localStorage.setItem(
              `${environment.appName}` + "_mobile",
              user.data.user.mobile
            );
          }
          return user;
        })
      );
  }

  tessellateverifyotp(mobileno: any, otp: any) {
    console.log("user");
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/Tessellate/verify-otp

        `,
        { mobile: mobileno, otp: otp }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            localStorage.setItem(
              `${environment.appName}` + "tessellate_token",
              user.data.token
            );
            localStorage.setItem(
              `${environment.appName}` + "_mobile",
              user.data.user.mobile
            );
          }
          return user;
        })
      );
  }

  cinaverifyotp(mobileno: any, otp: any) {
    console.log("user");
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/CinA/verify-otp

        `,
        { mobile: mobileno, otp: otp }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
           
            localStorage.setItem(
              `${environment.appName}` + "cina_token",
              user.data.token
            );
            localStorage.setItem(
              `${environment.appName}` + "_mobile",
              user.data.user.mobile
            );
          }
          return user;
        })
      );
  }

  erayaverifyotp(mobileno: any, otp: any) {
    console.log("user");
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/Falak/verify-otp

        `,
        { mobile: mobileno, otp: otp }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            console.log(user);
            localStorage.setItem(
              `${environment.appName}` + "eraya_token",
              user.data.token
            );
            localStorage.setItem(
              `${environment.appName}` + "_mobile",
              user.data.user.mobile
            );
          }
          return user;
        })
      );
  }

  basketballverifyotp(mobileno: any, otp: any) {
    console.log("user");
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/MBL2024/verify-otp

        `,
        { mobile: mobileno, otp: otp }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            console.log(user);
            localStorage.setItem(
              `${environment.appName}` + "basketball_token",
              user.data.token
            );
            localStorage.setItem(
              `${environment.appName}` + "_mobile",
              user.data.user.mobile
            );
          }
          return user;
        })
      );
  }


  mujoverifyotp(mobileno: any, otp: any) {
    console.log("user");
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/MUJO/verify-otp
        `,
        { mobile: mobileno, otp: otp }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            console.log(user);
            localStorage.setItem(
              `${environment.appName}` + "mujo_token",
              user.data.token
            );
            localStorage.setItem(
              `${environment.appName}` + "_mobile",
              user.data.user.mobile
            );
          }
          return user;
        })
      );
  }

  techsolsticeverifyotp(mobileno: any, otp: any) {
    console.log("user");
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/TechSolsticeNexus/verify-otp
        `,
        { mobile: mobileno, otp: otp }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            console.log(user);
            localStorage.setItem(
              `${environment.appName}` + "solstice_token",
              user.data.token
            );
            localStorage.setItem(
              `${environment.appName}` + "_mobile",
              user.data.user.mobile
            );
          }
          return user;
        })
      );
  }


  chesslogin(mobileno: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/Chess2024/login`,
        { mobile: mobileno }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  mahehostelslogin(mobileno: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/MaheHostelsBengaluruCampus/login`,
        { mobile: mobileno }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  iqaclogin(mobileno: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/iqac/login`,
        { mobile: mobileno }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  finaciallogin(mobileno: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/TDWFE/login`,
        { mobile: mobileno }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  lawlogin(mobileno: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/ConstructionLawSchoolBlr/login`,
        { mobile: mobileno }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  adhikshanalogin(mobileno: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/ansysLumerical/login`,
        { mobile: mobileno }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  reflektalogin(mobileno: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/reflekta/login`,
        { mobile: mobileno }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  aleylogin(mobileno: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/Aley/login`,
        { mobile: mobileno }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  bazaarlogin(mobileno: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/BazaarMic/login`,
        { mobile: mobileno }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  cricketlogin(mobileno: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/StaffCricket2025/login`,
        { mobile: mobileno }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  tessellatelogin(mobileno: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/Tessellate/login`,
        { mobile: mobileno }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  cinalogin(mobileno: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/CinA/login`,
        { mobile: mobileno }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  erayalogin(mobileno: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/Falak/login`,
        { mobile: mobileno }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  basketballlogin(mobileno: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/MBL2024/login`,
        { mobile: mobileno }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  mujologin(mobileno: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/MUJO/login`,
        { mobile: mobileno }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  techsolticelogin(mobileno: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/TechSolsticeNexus/login`,
        { mobile: mobileno }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  festlogin(mobileno: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/kala-fest/login`,
        { mobile: mobileno }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }


  chessverifyotp(mobileno: any, otp: any) {
    console.log("user");
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/Chess2024/verify-otp
        `,
        { mobile: mobileno, otp: otp }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            console.log(user);
            localStorage.setItem(
              `${environment.appName}` + "chess_token",
              user.data.token
            );
            localStorage.setItem(
              `${environment.appName}` + "_mobile",
              user.data.user.mobile
            );
          }
          return user;
        })
      );
  }

  mahehostelsverifyotp(mobileno: any, otp: any) {
    console.log("user");
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/conferences/MaheHostelsBengaluruCampus/verify-otp
        `,
        { mobile: mobileno, otp: otp }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            console.log(user);
            localStorage.removeItem(`${environment.appName}` + "conference_token");
            localStorage.removeItem(`${environment.appName}` + "agile_token");
            // localStorage.removeItem(`${environment.appName}` + "hostels_token");
            localStorage.setItem(
              `${environment.appName}` + "hostels_token",
              user.data.token
            );
            localStorage.setItem(
              `${environment.appName}` + "_mobile",
              user.data.user.mobile
            );
          }
          return user;
        })
      );
  }

  festverifyotp(mobileno: any, otp: any) {
    console.log("user");
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/kala-fest/verify-otp`,
        { mobile: mobileno, otp: otp }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            console.log(user);
            localStorage.setItem(
              `${environment.appName}` + "fest_token",
              user.data.token
            );
            localStorage.setItem(
              `${environment.appName}` + "_mobile",
              user.data.user.mobile
            );
          }
          return user;
        })
      );
  }


  localLoginWithMobileNumber(type: any, name: string, phone_number: string) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/register`,
        { loginType: type, name: name, phone_number: phone_number }
      )
      .pipe(
        map((user) => {
          localStorage.setItem(`${environment.appName}` + 'user_info',  JSON.stringify(user.data));
          if (user.status.code == 0) {
          }
          return user;
        })
      );
  }

  vehiclelocalLoginWithMobileNumber(type: any, name: string, phone_number: string) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/vehicle-reg/register`,
        { loginType: type, name: name, phone_number: phone_number }
      )
      .pipe(
        map((user) => {
          localStorage.setItem(`${environment.appName}` + 'user_info',  JSON.stringify(user.data));
          if (user.status.code == 0) {
          }
          return user;
        })
      );
  }

  localverifyotp(type: any, name: string, phone_number: string,otp:any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/verify-login-otp`,
        { loginType: type, name: name, phone_number: phone_number,otp:otp }
      )
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          // return false;
          if (user.status.code == 0) {
            localStorage.setItem(
              `${environment.appName}` + "_user_obj",
              JSON.stringify(user.data.user)
            );
            localStorage.setItem(
              `${environment.appName}` + "_user",
              user.data.access_token
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminName",
              user.data.user.name
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminId",
              user.data.user._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_userId",
              user.data.user._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_role",
              user.data.user.roleId
            );
            localStorage.setItem(
              `${environment.appName}` + "_status",
              user.data.user.status
            );
            // localStorage.setItem(`${environment.appName}` + '_domain', user.user.shop ? user.user.shop.domain : '');
          }
          console.log(user);

          return user;
        })
      );
  }

  vehiclelocalverifyotp(type: any, name: string, phone_number: string,otp:any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/vehicle-reg/verify-login-otp`,
        { loginType: type, name: name, phone_number: phone_number,otp:otp }
      )
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          // return false;
          if (user.status.code == 0) {
            localStorage.setItem(
              `${environment.appName}` + "_user_obj",
              JSON.stringify(user.data.user)
            );
            localStorage.setItem(
              `${environment.appName}` + "_vehicleuser",
              user.data.access_token
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminName",
              user.data.user.name
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminId",
              user.data.user._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_userId",
              user.data.user._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_role",
              user.data.user.roleId
            );
            localStorage.setItem(
              `${environment.appName}` + "_status",
              user.data.user.status
            );
            // _vehicleuserdetail
            localStorage.setItem(
              `${environment.appName}` + "_vehicleuserdetail",
              JSON.stringify(user.data.user)
            );
            // localStorage.setItem(`${environment.appName}` + '_domain', user.user.shop ? user.user.shop.domain : '');
          }
          console.log(user);

          return user;
        })
      );
  }

  almashineLogin(mobile) {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/getAlumnusByPhone?phone_no=` +
          mobile
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  almashineotp(mobile, otp) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/verifyAlumnusOtp`,
        { phone_no: JSON.stringify(mobile), otp: otp }
      )
      .pipe(
        map((user) => {
          console.log("asdasd");
          if (user.status.code == 0) {
            localStorage.setItem(
              `${environment.appName}` + "_almashinetoken",
              user.data.access_token
            );
            console.log(
              localStorage.getItem(`${environment.appName}` + "_almashinetoken")
            );
          }
          return user;
        })
      );
  }

  staffLoginWithEmail(type: any, roll_no: string, email: string) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/register`,
        { loginType: type, roll_no: roll_no, email: email }
      )
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          // return false;
          if (user.status.code == 0) {
            localStorage.setItem(
              `${environment.appName}` + "_user_obj",
              JSON.stringify(user.data.user)
            );
            // localStorage.setItem(`${environment.appName}` + '_user_room', JSON.stringify(user.room));
            // localStorage.setItem(`${environment.appName}` + '_user_room_details',user.user.room_details );
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(
              `${environment.appName}` + "_user",
              user.data.access_token
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminName",
              user.data.user.name
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminId",
              user.data.user._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_userId",
              user.data.user._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_role",
              user.data.user.roleId
            );
            localStorage.setItem(
              `${environment.appName}` + "_status",
              user.data.user.status
            );
            // localStorage.setItem(`${environment.appName}` + '_domain', user.user.shop ? user.user.shop.domain : '');
          }
          console.log(user);

          return user;
        })
      );
  }

  staffLoginWithMobileNumber(type: any, roll_no: string, phone_number: string) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/register`,
        { loginType: type, rollNumber: roll_no, phone_number: phone_number }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            
          }

          return user;
        })
      );
  }

  vehiclestaffLoginWithMobileNumber(type: any, roll_no: string, phone_number: string) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/vehicle-reg/register`,
        { loginType: type, rollNumber: roll_no, phone_number: phone_number }
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            
          }

          return user;
        })
      );
  }

  staffLoginverifyotp(type: any, roll_no: string, phone_number: string,otp:any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/verify-login-otp`,
        { loginType: type, rollNumber: roll_no, phone_number: phone_number,otp:otp }
      )
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          // return false;
          if (user.status.code == 0) {
            localStorage.setItem(
              `${environment.appName}` + "_user_obj",
              JSON.stringify(user.data.user)
            );
            localStorage.setItem(
              `${environment.appName}` + "_user",
              user.data.access_token
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminName",
              user.data.user.name
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminId",
              user.data.user._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_userId",
              user.data.user._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_role",
              user.data.user.roleId
            );
            localStorage.setItem(
              `${environment.appName}` + "_status",
              user.data.user.status
            );
            // localStorage.setItem(`${environment.appName}` + '_domain', user.user.shop ? user.user.shop.domain : '');
          }
          console.log(user);

          return user;
        })
      );
  }

  vehiclestaffLoginverifyotp(type: any, roll_no: string, phone_number: string,otp:any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/vehicle-reg/verify-login-otp`,
        { loginType: type, rollNumber: roll_no, phone_number: phone_number,otp:otp }
      )
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          // return false;
          if (user.status.code == 0) {
            localStorage.setItem(
              `${environment.appName}` + "_user_obj",
              JSON.stringify(user.data.user)
            );
            localStorage.setItem(
              `${environment.appName}` + "_vehicleuser",
              user.data.access_token
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminName",
              user.data.user.name
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminId",
              user.data.user._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_vehicleuserId",
              user.data.user._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_role",
              user.data.user.roleId
            );
            localStorage.setItem(
              `${environment.appName}` + "_status",
              user.data.user.status
            );
            // _vehicleuserdetail
            localStorage.setItem(
              `${environment.appName}` + "_vehicleuserdetail",
              JSON.stringify(user.data.user)
            );
            // localStorage.setItem(`${environment.appName}` + '_domain', user.user.shop ? user.user.shop.domain : '');
          }

          return user;
        })
      );
  }
  

  studentLoginWithEmail(type: any, roll_no: string, email: string) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/register`,
        { loginType: type, roll_no: roll_no, email: email }
      )
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          // return false;
          if (user.status.code == 0) {
            localStorage.setItem(
              `${environment.appName}` + "_user_obj",
              JSON.stringify(user.data.user)
            );
            // localStorage.setItem(`${environment.appName}` + '_user_room', JSON.stringify(user.room));
            // localStorage.setItem(`${environment.appName}` + '_user_room_details',user.user.room_details );
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(
              `${environment.appName}` + "_user",
              user.data.access_token
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminName",
              user.data.user.name
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminId",
              user.data.user._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_userId",
              user.data.user._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_role",
              user.data.user.roleId
            );
            localStorage.setItem(
              `${environment.appName}` + "_status",
              user.data.user.status
            );
            // localStorage.setItem(`${environment.appName}` + '_domain', user.user.shop ? user.user.shop.domain : '');
          }
          console.log(user);

          return user;
        })
      );
  }

  studentLoginWithMobileNumber(
    type: any,
    roll_no: string,
    phone_number: string,location:any
  ) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/register`,
        { loginType: type, rollNumber: roll_no, phone_number: phone_number,login_from :location}
      )
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          // return false;
          if (user.status.code == 0) {
           
          }
          return user;
        })
      );
  }

  vehiclestudentLoginWithMobileNumber(
    type: any,
    roll_no: string,
    phone_number: string
  ) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/vehicle-reg/register`,
        { loginType: type, rollNumber: roll_no, phone_number: phone_number }
      )
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          // return false;
          if (user.status.code == 0) {
           
          }
          return user;
        })
      );
  }

  studentLoginverifyotp(
    type: any,
    roll_no: string,
    phone_number: string,otp:any,from:any
  ) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/verify-login-otp`,
        { loginType: type, rollNumber: roll_no, phone_number: phone_number ,otp:otp,
          login_from:from}
      )
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          // return false;
          if (user.status.code == 0) {
            localStorage.setItem(
              `${environment.appName}` + "_user_obj",
              JSON.stringify(user.data.user)
            );
            localStorage.setItem(
              `${environment.appName}` + "_user",
              user.data.access_token
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminName",
              user.data.user.name
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminId",
              user.data.user._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_userId",
              user.data.user._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_role",
              user.data.user.roleId
            );
            localStorage.setItem(
              `${environment.appName}` + "_status",
              user.data.user.status
            );
            // localStorage.setItem(`${environment.appName}` + '_domain', user.user.shop ? user.user.shop.domain : '');
          }
          console.log(user);

          return user;
        })
      );
  }

  vehiclestudentLoginverifyotp(
    type: any,
    roll_no: string,
    phone_number: string,otp:any
  ) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/vehicle-reg/verify-login-otp`,
        { loginType: type, rollNumber: roll_no, phone_number: phone_number ,otp:otp}
      )
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          // return false;
          if (user.status.code == 0) {
            localStorage.setItem(
              `${environment.appName}` + "_user_obj",
              JSON.stringify(user.data.user)
            );
            localStorage.setItem(
              `${environment.appName}` + "_vehicleuser",
              user.data.access_token
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminName",
              user.data.user.name
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminId",
              user.data.user._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_vehicleuserId",
              user.data.user._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_role",
              user.data.user.roleId
            );
            localStorage.setItem(
              `${environment.appName}` + "_status",
              user.data.user.status
            );
            localStorage.setItem(
              `${environment.appName}` + "_vehicleuserdetail",
              JSON.stringify(user.data.user)
            );
            // localStorage.setItem(`${environment.appName}` + '_domain', user.user.shop ? user.user.shop.domain : '');
          }
          console.log(user);

          return user;
        })
      );
  }

  sendOtp(countrycode: any, mobile_no: any) {
    const formData: FormData = new FormData();
    formData.append("country_code", countrycode);
    formData.append("phone_no", mobile_no);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/send-otp`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  

  // this.f.name.value,this.f.email.value,this.f.phone_number.value,this.f.gender.value,this.f.dob.value,this.f.address.value
  verifyOtp(
    name: string,
    email: string,
    phone_number: string,
    gender: string,
    dob: string,
    address: string,
    otp: any,
    employee_code: any,
    institution_name: any,location:any
  ) {
    const formData = {
      loginType: 3,
      name: name,
      email: email,
      phone_number: phone_number,
      gender: gender,
      dob: dob,
      address: address,
      otp: otp,
      enrollment_Number: employee_code,
      institution: institution_name,
      location:location
    };
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/verify`,
        formData
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            localStorage.setItem(
              `${environment.appName}` + "_user_obj",
              JSON.stringify(user.data.user)
            );
            localStorage.setItem(
              `${environment.appName}` + "_user",
              user.data.access_token
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminName",
              user.data.user.name
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminId",
              user.data.user._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_userId",
              user.data.user._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_role",
              user.data.user.roleId
            );
            localStorage.setItem(
              `${environment.appName}` + "_status",
              user.data.user.status
            );
          }
          return user;
        })
      );
  }

  vehicleverifyOtp(
    name: string,
    email: string,
    phone_number: string,
    gender: string,
    dob: string,
    address: string,
    otp: any,
    employee_code: any,
    institution_name: any,location:any,company:any
  ) {
    const formData = {
      loginType: 3,
      name: name,
      email: email,
      phone_number: phone_number,
      gender: gender,
      dob: dob,
      address: address,
      otp: otp,
      enrollment_Number: employee_code,
      institution: institution_name,
      location:location,company:company
    };
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/vehicle-reg/verify`,
        formData
      )
      .pipe(
        map((user) => {
          if (user.status.code == 0) {
            console.log(user)
            localStorage.setItem(
              `${environment.appName}` + "_user_obj",
              JSON.stringify(user.data.user)
            );
            localStorage.setItem(
              `${environment.appName}` + "_vehicleuser",
              user.data.access_token
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminName",
              user.data.user.name
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminId",
              user.data.user._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_vehicleuserId",
              user.data.user._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_role",
              user.data.user.roleId
            );
            localStorage.setItem(
              `${environment.appName}` + "_status",
              user.data.user.status
            );
            localStorage.setItem(
              `${environment.appName}` + "_vehicleuserdetail",
              JSON.stringify(user.data.user)
            );
          }
          return user;
        })
      );
  }

  getCountry(): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/country`)
      .pipe(map((user) => user));
  }

  getState(id): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/web/states?countryId=` +
          id
      )
      .pipe(map((user) => user));
  }

  getUsers(page, search, filters): Observable<any> {
    if (!search) {
      search = "";
    }
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/user/get-user?page=` +
          page +
          "&search=" +
          search +
          "&filters=" +
          filters
      )
      .pipe(map((user) => user));
  }

  getSingleuser(id: any): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/user/get-userSingle/` +
          id
      )
      .pipe(map((user) => user));
  }

  logout() {
    // remove user from local storage to log user out
    // localStorage.removeItem(`${environment.appName}` + 'vendor_user');
    // localStorage.removeItem(`${environment.appName}` + '_role');
    localStorage.removeItem(`${environment.appName}` + "_user");
    localStorage.removeItem(`${environment.appName}` + "tessellate_token");

    localStorage.removeItem(`${environment.appName}` + "_vehicleuser");
    localStorage.removeItem(`${environment.appName}` + "tma_token");
    localStorage.removeItem(`${environment.appName}` + "chess_token");
    localStorage.removeItem(`${environment.appName}` + "fest_token");
    localStorage.removeItem(`${environment.appName}` + "iqac_token");
    localStorage.removeItem(`${environment.appName}` + "finacial_token");
    localStorage.removeItem(`${environment.appName}` + "adhikshana_token");
    localStorage.removeItem(`${environment.appName}` + "law_token");
    localStorage.removeItem(`${environment.appName}` + "eraya_token");
    localStorage.removeItem(`${environment.appName}` + "bazaar_token");

    localStorage.removeItem(`${environment.appName}` + "mujo_token");
    localStorage.removeItem(`${environment.appName}` + "solstice_token");
    localStorage.removeItem(`${environment.appName}` + "agile_token");
    localStorage.removeItem(`${environment.appName}` + "conference_token");
    localStorage.removeItem(`${environment.appName}` + "hostels_token");
    localStorage.removeItem(`${environment.appName}` + "_userid");
    localStorage.removeItem(`${environment.appName}` + "techlogintype");
    localStorage.removeItem(`${environment.appName}` + "agilelogintype");
    localStorage.removeItem(`${environment.appName}` + "nonmahelogintype");
    localStorage.removeItem(`${environment.appName}` + "daycare_token");
    localStorage.removeItem(`${environment.appName}` + "basketball_token");
    localStorage.removeItem(`${environment.appName}` + "faculty_token");
    localStorage.removeItem(`${environment.appName}` + "refleckta_token");
    localStorage.removeItem(`${environment.appName}` + "aley_token");
    localStorage.removeItem(`${environment.appName}` + "cina_token");
   localStorage.removeItem(`${environment.appName}` + 'cricket_token');
    // localStorage.removeItem(`${environment.appName}` + '_adminId');
    // localStorage.removeItem(`${environment.appName}` + '_status');
    // localStorage.removeItem(`${environment.appName}` + '_domain');
    // localStorage.removeItem(`${environment.appName}` + '_user_room_details');
    // localStorage.removeItem(`${environment.appName}` + '_userId');
  }
  getUser() {
    return this.http
      .get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-userSingle`)
      .pipe(map((user) => user));
  }
  getRoomDetails(id) {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/user/room_details/` + id
      )
      .pipe(map((user) => user));
  }

  public isAuth() {
    if (localStorage.getItem(`${environment.appName}` + "_user")) {
      return true;
    } else {
      return false;
    }
  }

  addBeneficiary(
    benef_type: any,
    name: string,
    email: string,
    phone_no: string,
    gender: string,
    dob: string,
    address: string,
    age: string
  ) {
    const formData = {
      benef_type: benef_type,
      name: name,
      email: email,
      phone_no: phone_no,
      gender: gender,
      dob: dob,
      address: address,
      under_age: age,
    };
    console.log(formData);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/addbeneficiary`,
        formData
      )
      .pipe(
        map((user) => {
          console.log(user);

          if (user.status.code == 0) {
            // localStorage.setItem(`${environment.appName}` + '_user', user.data.access_token);
            localStorage.setItem(
              `${environment.appName}` + "_adminName",
              user.data.name
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminId",
              user.data._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_role",
              user.data.parentId
            );
            localStorage.setItem(
              `${environment.appName}` + "_role",
              user.data.benefType
            );
            localStorage.setItem(
              `${environment.appName}` + "_status",
              user.data.active
            );
            localStorage.setItem(`${environment.appName}` + "_domain", "");
          }

          return user;
        })
      );
  }

  addblrBeneficiary(
    benef_type: any,
    name: string,
    email: string,
    phone_no: string,
    gender: string,
    dob: string,
    address: string,
    age: string
  ) {
    const formData = {
      benef_type: benef_type,
      name: name,
      email: email,
      phone_no: phone_no,
      gender: gender,
      dob: dob,
      address: address,
      under_age: age,
    };
    console.log(formData);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/blr/addbeneficiary`,
        formData
      )
      .pipe(
        map((user) => {
          console.log(user);

          if (user.status.code == 0) {
            // localStorage.setItem(`${environment.appName}` + '_user', user.data.access_token);
            localStorage.setItem(
              `${environment.appName}` + "_adminName",
              user.data.name
            );
            localStorage.setItem(
              `${environment.appName}` + "_adminId",
              user.data._id
            );
            localStorage.setItem(
              `${environment.appName}` + "_role",
              user.data.parentId
            );
            localStorage.setItem(
              `${environment.appName}` + "_role",
              user.data.benefType
            );
            localStorage.setItem(
              `${environment.appName}` + "_status",
              user.data.active
            );
            localStorage.setItem(`${environment.appName}` + "_domain", "");
          }

          return user;
        })
      );
  }

  // getMyAccount(): Observable<any> {

  //     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/acountdetails`).pipe(map(user => {
  //         console.log("user",user);
  //         return user

  //     }));
  // }

  //my-account details

  getMyAccount(): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/acountdetails`
      )
      .pipe(
        map((user) => {
          console.log("user", user);
          return user;
        })
      );
  }

  getblrMyAccount(): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/blr/acountdetails`
      )
      .pipe(
        map((user) => {
          console.log("user", user);
          return user;
        })
      );
  }

  getvehicleAccount(id:any): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/vehicleRegistration/booking-details`
      )
      .pipe(
        map((user) => {
          console.log("user", user);
          return user;
        })
      );
  }

  getAccount(id:any): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/booking-details/`+id
      )
      .pipe(
        map((user) => {
          console.log("user", user);
          return user;
        })
      );
  }

  updatestatus(id:any,status:any): Observable<any> {
    return this.http
      .put<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/update-status/`+id,{status:status}
      )
      .pipe(
        map((user) => {
          console.log("user", user);
          return user;
        })
      );
  }


  getMarenaDetails(page: any): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/marena/membershipdetails?` +
          "&page=" +
          page
      )
      .pipe(
        map((user) => {
          console.log("user", user);
          return user;
        })
      );
  }

  getblrMarenaDetails(page: any): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/blr/marena/membershipdetails?` +
          "&page=" +
          page
      )
      .pipe(
        map((user) => {
          console.log("user", user);
          return user;
        })
      );
  }

  getAdHocDetails(page: any): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/quickpay/paid-details/list?` +
          "&page=" +
          page
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  getMarenaSwimmingDetails(page: any): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/swimming/membershipdetails?` +
          "&page=" +
          page
      )
      .pipe(
        map((user) => {
          console.log("user", user);
          return user;
        })
      );
  }

  getMarenaInvoiceDetails(_id: any): Observable<any> {
    return this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/users/marena/downloadinvoice?` +
          "&_id=" +
          _id,
        { responseType: "blob" as "json" }
      )
      .pipe(
        map((user) => {
          console.log("user", user);
          return user;
        })
      );
  }

  getblrMarenaInvoiceDetails(_id: any): Observable<any> {
    return this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/users/blr/marena/downloadinvoice?` +
          "&_id=" +
          _id,
        { responseType: "blob" as "json" }
      )
      .pipe(
        map((user) => {
          console.log("user", user);
          return user;
        })
      );
  }


  uploadImage(image:any){
    const formData = new FormData();
    formData.append("image", image );
    return this.http.post<any>(
      `${environment.apiUrl}${environment.apiPrefix}/users/blr/upload-image`,
      formData
    );

  }

  getSwimmingPoolInvoiceDetails(
    val3: any,
    val1: any,
    val4: any,
    val5: any,
    val6: any,
    val7: any
  ) {
    console.log("entering");
    const formData = {
      loginType: val3,
      membershipId: val1,
      category: val4,
      known_status: val5,
      under_age: val6,
      categoryType: val7,
    };

    console.log(formData);
    return this.http
      .post<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/users/swimming/downloadinvoice`,
        formData,
        { responseType: "blob" as "json" }
      )
      .pipe(
        map((user) => {
          console.log("user", user);
          return user;
        })
      );
  }

  getadhocInvoiceDetails(_id: any): Observable<any> {
    return this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/users/quickpay/download-invoice/` +
          _id,
        { responseType: "blob" as "json" }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  getHackathon(): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/hackathon`)
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  gethackthonDetails(_id: any): Observable<any> {
    return this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/users/hackathon/download?id=` +
          _id,
        { responseType: "blob" as "json" }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  getalmashineinvoice(_id: any, no: any): Observable<any> {
    return this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/users/downloadAlumnusUpdateInvoice?_id=` +
          _id +
          "&invoice_id=" +
          no,
        { responseType: "blob" as "json" }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  getCourseraInvoice(_id: any, no: any): Observable<any> {
    return this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/users/downloadInvoiceCoursera?_id=` +
          _id +
          "&invoice_id=" +
          no,
        { responseType: "blob" as "json" }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }
}
