import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';


@Injectable()
export class mareenaService {
    constructor(private http: HttpClient) { }


    //Mareena Sports Complex
    getBeneficiary (): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/beneficiary`).pipe(map(user => user));
    }

    getblrBeneficiary (): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/blr/beneficiary`).pipe(map(user => user));
    }

    getswimmingBeneficiary (): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/sports-beneficiary`).pipe(map(user => user));
    }

    getMareenaSportsComplexCategory(type:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/marena/category?membershipPeriod=`+type).pipe(map(user => user));
    }

    getblrMareenaSportsComplexCategory(type:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/blr/marena/category?membershipPeriod=`+type).pipe(map(user => user));
    }

    getMareenaSportsComplexCategoryAmount(catList:any,type:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/marena/calculate?category=`+catList + `&membershipPeriod=` + type).pipe(map(user => user));
    }

    getblrMareenaSportsComplexCategoryAmount(catList:any,type:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/blr/marena/calculate?category=`+catList + `&membershipPeriod=` + type).pipe(map(user => user));
    }

    storeSportsComplexDetailsBeforePayment(membershipType: any, category: any,membershipPeriod:any,amount:any,type:any) {
        var formData = {
            'membershipType': membershipType,
            'category': category,
            'membershipPeriod':membershipPeriod,
            'amount':amount,
            'type':type
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/marena/addmembership`, formData).pipe(map(user => {
            return user;
        }));

    }

    storeblrSportsComplexDetailsBeforePayment(membershipType: any, category: any,membershipPeriod:any,amount:any,type:any) {
        var formData = {
            'membershipType': membershipType,
            'category': category,
            'membershipPeriod':membershipPeriod,
            'amount':amount,
            'type':type
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/blr/marena/addmembership`, formData).pipe(map(user => {
            return user;
        }));

    }

    encryptdata(request){
        let url = `${environment.apiUrl}${environment.apiPrefix}/users/encryptFormData`;
        let data = {
        request : request
        }
        return this.http.get(url,{params:data})
    }

    //Swimming-Pool

    getSwimmingPoolCategory(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/swimming/category`).pipe(map(user => user));
    }

    getSwimmingPoolDetails(category:any,membershipPeriod:any,know_status:any,membership_type:any): Observable<any> {
        let params = new HttpParams();
        params = params.append("category", category);
        params = params.append("membershipPeriod", membershipPeriod);
        params = params.append("know_status", know_status);
        if(membership_type)
        {
        params = params.append("membershipType", membership_type);
        }
        const options = {
            params: params
        };
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/swimming/calculate`,options).pipe(map(user => user));
    }
    storeSwimmingPoolDetailssBeforePayment(type:any,membershipType: any, category: any,membershipPeriod:any,healthProblem:any,known_status:any,level:any,amount:any,swimmingpoolDuration:any,healthProbDetail:any,actual_amount:any,end:any) {
        var formData = {
            'type':type,
            'membershipType': membershipType,
            'category': category,
            'membershipPeriod':membershipPeriod,
            'amount':amount,
            'healthProblem':healthProblem,
            'known_status':known_status,
            'level':level,
            'choosePeriod':swimmingpoolDuration,
            'healthProblem_dsc':healthProbDetail,
            'actual_amount':actual_amount,
            // 'validTill':end
            // 'type':type
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/swimming/addmembership`, formData).pipe(map(user => {
            return user;
        }));

    }


    //ad-hoc

    getInstitute(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/institution`).pipe(map(user => user));
    }

    getPurpose(amount: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/quickpay?amount=` + amount).pipe(map(user => user));
    }

    getPurposesingle(id: any, amount:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/quickpay/` + id + '?amount=' + amount).pipe(map(user => user));
    }

    quickpay(purpose:any, description:any, institution: any, tot_amount:any,others:any,purpose_name:any) {
        var formData = {
            'purpose':purpose,
            'description': description,
            'institution': institution,
            'tot_amount':tot_amount,
            'others':others,
            'purpose_name': purpose_name
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/quickpay/create`, formData).pipe(map(user => {
            return user;
        }));
    }
    
    storeHackathon(
        team_lead_name: any,
      roll_number: any,
      contact_number:any,
      email_id: any,
      department: any,
      institute: any,
      team_member_1: any,
      team_member_2: any,
      team_member_3: any,
      project_name: any,
      problem_statement: any,
      need: any,
      c_solutions_limitations: any,
      proposed_solution: any,
      plan_of_action:any,
      possible_issues: any,
      overcoming_issues: any
        ) {
            var formData = new FormData();
            formData.append('team_lead_name',team_lead_name);
            formData.append('roll_number',roll_number);
            formData.append('contact_number',contact_number);
            formData.append('email_id',email_id);
            formData.append('department',department);
            formData.append('institute',institute);
            formData.append('team_member_1',JSON.stringify(team_member_1));
            formData.append('team_member_2',JSON.stringify(team_member_2));
            formData.append('team_member_3',JSON.stringify(team_member_3));
            formData.append('project_name',project_name);
            formData.append('problem_statement',problem_statement);
            formData.append('need',need);
            formData.append('c_solutions_limitations',c_solutions_limitations);
            formData.append('proposed_solution',proposed_solution);
            formData.append('plan_of_action',plan_of_action);
            formData.append('possible_issues',possible_issues);
            formData.append('overcoming_issues',overcoming_issues);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/hackathon/create`, formData).pipe(map(user => {
            return user;
        }));

    }

    regHackathon(
        team_lead_name: any,
      roll_number: any,
      contact_number:any,
      email_id: any,
      department: any,
      institute: any,
      team_member_1: any,
      team_member_2: any,
      team_member_3: any,
      project_name: any,
      problem_statement: any,
      need: any,
      c_solutions_limitations: any,
      proposed_solution: any,
      plan_of_action:any,
      possible_issues: any,
      overcoming_issues: any
        ) {
            var formData = new FormData();
            formData.append('team_lead_name',team_lead_name);
            formData.append('roll_number',roll_number);
            formData.append('contact_number',contact_number);
            formData.append('email_id',email_id);
            formData.append('department',department);
            formData.append('institute',institute);
            formData.append('team_member_1',JSON.stringify(team_member_1));
            formData.append('team_member_2',JSON.stringify(team_member_2));
            formData.append('team_member_3',JSON.stringify(team_member_3));
            formData.append('project_name',project_name);
            formData.append('problem_statement',problem_statement);
            formData.append('need',need);
            formData.append('c_solutions_limitations',c_solutions_limitations);
            formData.append('proposed_solution',proposed_solution);
            formData.append('plan_of_action',plan_of_action);
            formData.append('possible_issues',possible_issues);
            formData.append('overcoming_issues',overcoming_issues);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/hackathon-all/create`, formData).pipe(map(user => {
            return user;
        }));

    }

    getHackathonDownload(id:any): Observable<any> {
        return this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/users/hackathon/download?id=` +id, { responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
    }

    verifypayment(razorpay_order_id: any, razorpay_payment_id: any,razorpay_signature:any,merchant_param:any,name:any,contact:any,amount:any,currency:any) {
        var formData = {
            'razorpay_order_id': razorpay_order_id,
            'razorpay_payment_id': razorpay_payment_id,
            'razorpay_signature':razorpay_signature,
            'merchant_param':merchant_param,
            'name':name,
            'contact':contact,
            'amount':amount,
            'currency':currency
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/verify-payment`, formData).pipe(map(user => {
            return user;
        }));

    }
 
   
}
