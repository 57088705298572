// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// export const environment = {
//   appName: "mareena",
//   production: false,

//   //apiUrl: "https://api.manipal.edu/",

//   //  apiUrl: 'https://api.manipal.edu/',
//     apiUrl: 'https://dev-api.manipal.edu/',

//   //  apiUrl:'https://api.manipal.edu/',
//   //production

//   // apiUrl:'http://marena-public-alb-2123897715.ap-south-1.elb.amazonaws.com/',

//   //  apiUrl: 'http://localhost:3000/',  //development

//   //   apiUrl: 'https://dev-api.manipal.edu/',
//   //  apiUrl:'https://api.manipal.edu/',

//   oneSignalAppId: "c382baff-142f-4e16-b7c4-f6133d400ea4", //dev
//   // media_domain: "https://d2dkf813gb84zq.cloudfront.net/development", //dev
//   media_domain: 'https://d2dkf813gb84zq.cloudfront.net/production', //dev
//   apiPrefix: "api/v1",
//   imgWidth: "1280",
//   imgHeight: "960",
//   accessCode: "AVBS02GD63AJ23SBJA",
//   payment_accesscode: "AVFH20JH06CA55HFAC",
//   payment_url:
//     "https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction",
//   // rzp_key_1:'rzp_test_JpcNLjAfBy2F9o' //marena test key

//   // rzp_key_1: "rzp_test_p82Mn1nATmM9eC", //convocation test key
//   rzp_key_1: "rzp_live_hBAY8eERWIQjiX"
// };
export const environment = {
  appName: "mareena",
  production: false,

  //apiUrl: "https://api.manipal.edu/",

  //  apiUrl:'https://api.manipal.edu/',
   apiUrl: 'https://dev-api.manipal.edu/',

  //  apiUrl:'https://api.manipal.edu/',
  //production

  // apiUrl:'http://marena-public-alb-2123897715.ap-south-1.elb.amazonaws.com/',

  //  apiUrl: 'http://localhost:3000/',  //development

  //   apiUrl: 'https://dev-api.manipal.edu/',
    // apiUrl:'https://api.manipal.edu/',

  oneSignalAppId: "c382baff-142f-4e16-b7c4-f6133d400ea4", //dev
   media_domain: "https://d2dkf813gb84zq.cloudfront.net/development", //dev
  // media_domain: 'https://d2dkf813gb84zq.cloudfront.net/production', //dev
  apiPrefix: "api/v1",
  imgWidth: "1280",
  imgHeight: "960",
  accessCode: "AVBS02GD63AJ23SBJA",
  payment_accesscode: "AVMA04JI41AZ87AMZA",
  payment_url:
    "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction",
  // rzp_key_1:'rzp_test_JpcNLjAfBy2F9o' //marena test key

  rzp_key_1: "rzp_test_SEhK9UD69IVxPr", //convocation test key
  // rzp_key_1 : "rzp_live_hBAY8eERWIQjiX"
};
