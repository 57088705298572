import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';


@Injectable()
export class marathonService {
    constructor(private http: HttpClient) { }

    getCategory(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/category`).pipe(map(user => user));
    }


    submitContact(name: any, mobile_no: any,email:any,message:any) {
        var formData = {
            'name': name,
            'mobile_no': mobile_no,
            'email':email,
            'message':message
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/contact`, formData).pipe(map(user => {
            return user;
        }));

    }

    


    getshirt(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/t-shirt`).pipe(map(user => user));
    }

    getsingleshirt(id:any): Observable<any> {
       
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/t-shirt/` +id).pipe(map(user => user));
    }

    getsinglereg(id:any): Observable<any> {
       
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/registration-list/` +id).pipe(map(user => user));
    }


    getorderdetails(id:any): Observable<any> {
       
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/getRegistration/` +id).pipe(map(user => user));
    }

    getinstitute(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/institute`).pipe(map(user => user));
    }


    checkstatus(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/registeration`).pipe(map(user => user));
    }


    getuserreg(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/getuser`).pipe(map(user => user));
    }


    getregistration(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/getcancelwithzero`).pipe(map(user => user));
    }

    getpendingregistration(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/getstatuszero`).pipe(map(user => user));
    }


    
    

    getCancelledRegistration(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/getcancel`).pipe(map(user => user));
    }




    submit_register1(
        name:any,
        email:any,
        gender:any,
        phone_number:any,
        blood_group:any,
        t_shirt_size:any,
        em_contact_name:any,
        em_contact_num:any,
        vaccine_status:any,
        category:any,
        age:any,
        attendee_type:any,
        type:any,
        name_of_institute:any,
        register_no:any,
        year:any,
        course:any,
        reasearch_associate:any,
        employee_id:any,
        designation:any,
        department:any,
        userid:any,
        total_pay:any
    
        ){
        // const formData: FormData = new FormData();
        // formData.append('name', name);
        // formData.append('email', email);
        // formData.append('gender', gender);
        // formData.append('phone_number', phone_number);
        // formData.append('blood_group', blood_group);
        // formData.append('t_shirt_size', t_shirt_size);
        // formData.append('em_contact_name', em_contact_name);
        // formData.append('em_contact_num',  em_contact_num);
        // formData.append('vaccine_status', vaccine_status);
        // formData.append('category',  JSON.stringify(category));
        // formData.append('age', age);
        // formData.append('attendee_type', attendee_type);
        
        // formData.append('type', type);
        // formData.append('name_of_institute', name_of_institute);
        // formData.append('register_no', register_no); 
        // formData.append('year', year);
        // formData.append('course', course);
        // formData.append('reasearch_associate', reasearch_associate);
        // formData.append('employee_id', employee_id);
        // formData.append('designation', designation);
        // formData.append('department', department);

        var formData = {
            'name': name,
            'email': email,
       'gender': gender,
       'phone_number': phone_number,
       'blood_group': blood_group,
       't_shirt_size': t_shirt_size,
       'em_contact_name': em_contact_name,
       'em_contact_num':  em_contact_num,
       'vaccine_status': vaccine_status,
       'category':  category,
       'age': age,
       'attendee_type': attendee_type,
        
       'type': type,
       'name_of_institute': name_of_institute,
       'register_no': register_no, 
       'year': year,
       'course': course,
       'reasearch_associate': reasearch_associate,
       'employee_id': employee_id,
       'designation': designation,
       'department': department,
       'userId':userid,
       'total_amount':total_pay,
    
        }

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/registeration` , formData).pipe(map(user => {
            return user;
        }));
    }

    submit_register(
        name:any,
        email:any,
        gender:any,
        phone_number:any,
        blood_group:any,
        t_shirt_size:any,
        em_contact_name:any,
        em_contact_num:any,
        vaccine_status:any,
        category:any,
        age:any,
        attendee_type:any,
        type:any,
        name_of_institute:any,
        register_no:any,
        year:any,
        course:any,
        reasearch_associate:any,
        employee_id:any,
        designation:any,
        department:any,
        userid:any,
        total_pay:any,
        status:any
        ){
        // const formData: FormData = new FormData();
        // formData.append('name', name);
        // formData.append('email', email);
        // formData.append('gender', gender);
        // formData.append('phone_number', phone_number);
        // formData.append('blood_group', blood_group);
        // formData.append('t_shirt_size', t_shirt_size);
        // formData.append('em_contact_name', em_contact_name);
        // formData.append('em_contact_num',  em_contact_num);
        // formData.append('vaccine_status', vaccine_status);
        // formData.append('category',  JSON.stringify(category));
        // formData.append('age', age);
        // formData.append('attendee_type', attendee_type);
        
        // formData.append('type', type);
        // formData.append('name_of_institute', name_of_institute);
        // formData.append('register_no', register_no); 
        // formData.append('year', year);
        // formData.append('course', course);
        // formData.append('reasearch_associate', reasearch_associate);
        // formData.append('employee_id', employee_id);
        // formData.append('designation', designation);
        // formData.append('department', department);

        var formData = {
            'name': name,
            'email': email,
       'gender': gender,
       'phone_number': phone_number,
       'blood_group': blood_group,
       't_shirt_size': t_shirt_size,
       'em_contact_name': em_contact_name,
       'em_contact_num':  em_contact_num,
       'vaccine_status': vaccine_status,
       'category':  category,
       'age': age,
       'attendee_type': attendee_type,
        
       'type': type,
       'name_of_institute': name_of_institute,
       'register_no': register_no, 
       'year': year,
       'course': course,
       'reasearch_associate': reasearch_associate,
       'employee_id': employee_id,
       'designation': designation,
       'department': department,
       'userId':userid,
       'total_amount':total_pay,
       'status':status
        }

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/registeration` , formData).pipe(map(user => {
            return user;
        }));
    }


    update_register1(
        id:any,
        name:any,
        email:any,
        gender:any,
        phone_number:any,
        blood_group:any,
        t_shirt_size:any,
        em_contact_name:any,
        em_contact_num:any,
        vaccine_status:any,
        category:any,
        age:any,
        attendee_type:any,
        type:any,
        name_of_institute:any,
        register_no:any,
        year:any,
        course:any,
        reasearch_associate:any,
        employee_id:any,
        designation:any,
        department:any,
        userid:any,
        total_pay:any,
        orderID:any,
    
        ){
        // const formData: FormData = new FormData();
        // formData.append('name', name);
        // formData.append('email', email);
        // formData.append('gender', gender);
        // formData.append('phone_number', phone_number);
        // formData.append('blood_group', blood_group);
        // formData.append('t_shirt_size', t_shirt_size);
        // formData.append('em_contact_name', em_contact_name);
        // formData.append('em_contact_num',  em_contact_num);
        // formData.append('vaccine_status', vaccine_status);
        // formData.append('category',  JSON.stringify(category));
        // formData.append('age', age);
        // formData.append('attendee_type', attendee_type);
        
        // formData.append('type', type);
        // formData.append('name_of_institute', name_of_institute);
        // formData.append('register_no', register_no); 
        // formData.append('year', year);
        // formData.append('course', course);
        // formData.append('reasearch_associate', reasearch_associate);
        // formData.append('employee_id', employee_id);
        // formData.append('designation', designation);
        // formData.append('department', department);

        var formData = {
            'name': name,
            'email': email,
       'gender': gender,
       'phone_number': phone_number,
       'blood_group': blood_group,
       't_shirt_size': t_shirt_size,
       'em_contact_name': em_contact_name,
       'em_contact_num':  em_contact_num,
       'vaccine_status': vaccine_status,
       'category':  category,
       'age': age,
       'attendee_type': attendee_type,
        
       'type': type,
       'name_of_institute': name_of_institute,
       'register_no': register_no, 
       'year': year,
       'course': course,
       'reasearch_associate': reasearch_associate,
       'employee_id': employee_id,
       'designation': designation,
       'department': department,
       'userId':userid,
       'total_amount':total_pay,
       'orderID':orderID
    
        }

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/registeration/`+id , formData).pipe(map(user => {
            return user;
        }));
    }

    update_registeration(
        id:any,
        name:any,
        email:any,
        gender:any,
        phone_number:any,
        blood_group:any,
        t_shirt_size:any,
        em_contact_name:any,
        em_contact_num:any,
        vaccine_status:any,
        category:any,
        age:any,
        attendee_type:any,
        type:any,
        name_of_institute:any,
        register_no:any,
        year:any,
        course:any,
        reasearch_associate:any,
        employee_id:any,
        designation:any,
        department:any,
        userid:any,
        total_pay:any,
        status:any,
        orderID:any
        ){
        // const formData: FormData = new FormData();
        // formData.append('name', name);
        // formData.append('email', email);
        // formData.append('gender', gender);
        // formData.append('phone_number', phone_number);
        // formData.append('blood_group', blood_group);
        // formData.append('t_shirt_size', t_shirt_size);
        // formData.append('em_contact_name', em_contact_name);
        // formData.append('em_contact_num',  em_contact_num);
        // formData.append('vaccine_status', vaccine_status);
        // formData.append('category',  JSON.stringify(category));
        // formData.append('age', age);
        // formData.append('attendee_type', attendee_type);
        
        // formData.append('type', type);
        // formData.append('name_of_institute', name_of_institute);
        // formData.append('register_no', register_no); 
        // formData.append('year', year);
        // formData.append('course', course);
        // formData.append('reasearch_associate', reasearch_associate);
        // formData.append('employee_id', employee_id);
        // formData.append('designation', designation);
        // formData.append('department', department);

        var formData = {
            'name': name,
            'email': email,
       'gender': gender,
       'phone_number': phone_number,
       'blood_group': blood_group,
       't_shirt_size': t_shirt_size,
       'em_contact_name': em_contact_name,
       'em_contact_num':  em_contact_num,
       'vaccine_status': vaccine_status,
       'category':  category,
       'age': age,
       'attendee_type': attendee_type,
        
       'type': type,
       'name_of_institute': name_of_institute,
       'register_no': register_no, 
       'year': year,
       'course': course,
       'reasearch_associate': reasearch_associate,
       'employee_id': employee_id,
       'designation': designation,
       'department': department,
       'userId':userid,
       'total_amount':total_pay,
       'status':status,
       'orderID':orderID
        }

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/registeration/`+id , formData).pipe(map(user => {
            return user;
        }));
    }




    user_register(
        name:any,
        email:any,
        gender:any,
        phone_number:any,
        blood_group:any,
        t_shirt_size:any,
        em_contact_name:any,
        em_contact_num:any,
        vaccine_status:any,
        category:any,
        age:any,
       userid:any
        ){
   

        var formData = {
            'name': name,
            'email': email,
       'gender': gender,
       'phone_number': phone_number,
       'blood_group': blood_group,
       't_shirt_size': t_shirt_size,
       'em_contact_name': em_contact_name,
       'em_contact_num':  em_contact_num,
       'vaccine_status': vaccine_status,
       'category':  category,
       'age': age,
       'userId':userid
     
        }

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/user-registration` , formData).pipe(map(user => {
            return user;
        }));
    }


    update_register(
        id:any,
        name:any,
        email:any,
        gender:any,
        phone_number:any,
        blood_group:any,
        t_shirt_size:any,
        em_contact_name:any,
        em_contact_num:any,
        vaccine_status:any,
        category:any,
        age:any,
       userid:any
        ){
   

        var formData = {
            'name': name,
            'email': email,
       'gender': gender,
       'phone_number': phone_number,
       'blood_group': blood_group,
       't_shirt_size': t_shirt_size,
       'em_contact_name': em_contact_name,
       'em_contact_num':  em_contact_num,
       'vaccine_status': vaccine_status,
       'category':  category,
       'age': age,
       'userId':userid
     
        }

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/registeration/details/`+id , formData).pipe(map(user => {
            return user;
        }));
    }


    cancel_race(
         id:any,
      
        ){
   

        var formData = {
            cancel_status:1
        
           
        }

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/cancel/`+id , formData).pipe(map(user => {
            return user;
        }));
    }

    deleteRace(id: any) {

        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/users/user/` + id).pipe(map(user => {
            return user;
        }));
    }

    

    getSingleCategory(id:any): Observable<any> {
       
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/getCategory/` +id).pipe(map(user => user));
    }


}
