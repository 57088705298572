import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available

    if (localStorage.getItem(`${environment.appName}` + "_user")) {
      let token = localStorage.getItem(`${environment.appName}` + "_user");
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }
    else if (localStorage.getItem(`${environment.appName}` + "_vehicleuser")) {
      let token = localStorage.getItem(`${environment.appName}` + "_vehicleuser");
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }  else if (localStorage.getItem(`${environment.appName}` + "_token")) {
      let token = localStorage.getItem(`${environment.appName}` + "_token");
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } else if (
      localStorage.getItem(`${environment.appName}` + "_almashinetoken")
    ) {
      let token = localStorage.getItem(
        `${environment.appName}` + "_almashinetoken"
      );
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } 
    else if (
      localStorage.getItem(`${environment.appName}` + "tma_token")
    ) {
      let token = localStorage.getItem(
        `${environment.appName}` + "tma_token"
      );
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } 
    else if (
      localStorage.getItem(`${environment.appName}` + "chess_token")
    ) {
      let token = localStorage.getItem(
        `${environment.appName}` + "chess_token"
      );
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } 
    else if (
      localStorage.getItem(`${environment.appName}` + "fest_token")
    ) {
      let token = localStorage.getItem(
        `${environment.appName}` + "fest_token"
      );
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } else if (
      localStorage.getItem(`${environment.appName}` + "iqac_token")
    ) {
      let token = localStorage.getItem(
        `${environment.appName}` + "iqac_token"
      );
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } 
    else if (
      localStorage.getItem(`${environment.appName}` + "finacial_token")
    ) {
      let token = localStorage.getItem(
        `${environment.appName}` + "finacial_token"
      );
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } 
    else if (
      localStorage.getItem(`${environment.appName}` + "law_token")
    ) {
      let token = localStorage.getItem(
        `${environment.appName}` + "law_token"
      );
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } else if (
      localStorage.getItem(`${environment.appName}` + "faculty_token")
    ) {
      let token = localStorage.getItem(
        `${environment.appName}` + "faculty_token"
      );
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } 

    else if (
      localStorage.getItem(`${environment.appName}` + "refleckta_token")
    ) {
      let token = localStorage.getItem(
        `${environment.appName}` + "refleckta_token"
      );
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } 
    else if (
      localStorage.getItem(`${environment.appName}` + "aley_token")
    ) {
      let token = localStorage.getItem(
        `${environment.appName}` + "aley_token"
      );
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } 
    else if (
      localStorage.getItem(`${environment.appName}` + "eraya_token")
    ) {
      let token = localStorage.getItem(
        `${environment.appName}` + "eraya_token"
      );
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } 
    else if (
      localStorage.getItem(`${environment.appName}` + "cina_token")
    ) {
      let token = localStorage.getItem(
        `${environment.appName}` + "cina_token"
      );
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } 
    else if (
      localStorage.getItem(`${environment.appName}` + "mujo_token")
    ) {
      let token = localStorage.getItem(
        `${environment.appName}` + "mujo_token"
      );
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } else if (
      localStorage.getItem(`${environment.appName}` + "solstice_token")
    ) {
      let token = localStorage.getItem(
        `${environment.appName}` + "solstice_token"
      );
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } 
    else if (
      localStorage.getItem(`${environment.appName}` + "agile_token")
    ) {
      let token = localStorage.getItem(
        `${environment.appName}` + "agile_token"
      );
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }
    
    else if (
      localStorage.getItem(`${environment.appName}` + "conference_token")
    ) {
      let token = localStorage.getItem(
        `${environment.appName}` + "conference_token"
      );
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }
    else if (
      localStorage.getItem(`${environment.appName}` + "hostels_token")
    ) {
      let token = localStorage.getItem(
        `${environment.appName}` + "hostels_token"
      );
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } else if (
      localStorage.getItem(`${environment.appName}` + "daycare_token")
    ) {
      let token = localStorage.getItem(
        `${environment.appName}` + "daycare_token"
      );
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }
    else if (
      localStorage.getItem(`${environment.appName}` + "basketball_token")
    ) {
      let token = localStorage.getItem(
        `${environment.appName}` + "basketball_token"
      );
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }
    else if (
      localStorage.getItem(`${environment.appName}` + "bazaar_token")
    ) {
      let token = localStorage.getItem(
        `${environment.appName}` + "bazaar_token"
      );
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }
    else if (
      localStorage.getItem(`${environment.appName}` + "cricket_token")
    ) {
      let token = localStorage.getItem(
        `${environment.appName}` + "cricket_token"
      );
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }
    else if (
      localStorage.getItem(`${environment.appName}` + "tessellate_token")
    ) {
      let token = localStorage.getItem(
        `${environment.appName}` + "tessellate_token"
      );
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }else {
      let token = localStorage.getItem(
        `${environment.appName}` + "vendor_user"
      );

      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }

    return next.handle(request);
  }
}
