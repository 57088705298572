import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";

import { HttpHeaders, HttpErrorResponse } from "@angular/common/http";
@Injectable()
export class AlmashineService {
  getinstitution(): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/listInstitutes`
      )
      .pipe(map((user) => user));
  }

  getstate(): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/listStates`
      )
      .pipe(map((user) => user));
  }

  getbranch(id): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/courseInstitute?institute_code=` +
          id
      )
      .pipe(map((user) => user));
  }

  getAmount(card, id): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/calculatePrice?collect_card=` +
          card +
          "&state_id=" +
          id
      )
      .pipe(map((user) => user));
  }

  getAmountCoursera(id): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/calculatePriceCoursera?state_id=` +
          id
      )
      .pipe(map((user) => user));
  }

  getalmashinedata(id): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/viewAlumnusData?id=` +
          id
      )
      .pipe(map((user) => user));
  }

  getCourseradata(id): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/viewAlumnusDataCoursera?id=` +
          id
      )
      .pipe(map((user) => user));
  }

  getAlmashineUser(phone_no): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/checkAlmashineUser?phone_no=` +
          phone_no
      )
      .pipe(map((user) => user));
  }

  getCourseraUser(phone_no): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/checkCourseraUser?phone_no=` +
          phone_no
      )
      .pipe(map((user) => user));
  }

  constructor(private http: HttpClient) {}
  register(
    name: any,
    reg_no: any,
    address: any,
    city: any,
    pincode: any,
    mobile_no: any,
    institution: any,
    branch: any,
    email: any,
    state: any,
    card: any,
    amount: any,
    photo: File
  ) {
    console.log(amount);
    const formData: FormData = new FormData();

    formData.append("alumni_id_image", photo);
    formData.append("alumni_name", name);
    formData.append("register_no", reg_no);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("pincode", pincode);
    formData.append("phone_no", mobile_no);
    formData.append("institution", institution);
    formData.append("branch", branch);
    formData.append("email", email);
    formData.append("state", state);
    formData.append("collect_card", card);
    formData.append("amount", amount);

    // http://192.168.0.111:7000/api/v1/admin/almashine/listAllAlumni?page=1
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/alumnusRegistration`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  registerCoursera(
    name: any,
    reg_no: any,
    address: any,
    city: any,
    pincode: any,
    mobile_no: any,
    institution: any,
    branch: any,
    email: any,
    state: any,

    amount: any
  ) {
    console.log(amount);
    const formData: FormData = new FormData();

    formData.append("alumni_name", name);
    formData.append("register_no", reg_no);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("pincode", pincode);
    formData.append("phone_no", mobile_no);
    formData.append("institution", institution);
    formData.append("branch", branch);
    formData.append("email", email);
    formData.append("state", state);

    formData.append("amount", amount);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/courseraRegistration`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }
  updateaddress(
    id: any,
    name: any,
    reg_no: any,
    address: any,
    city: any,
    pincode: any,
    mobile_no: any,
    institution: any,
    branch: any,
    email: any,
    state: any,
    card: any,
    amount: any,
    photo: File
  ) {
    console.log(amount);
    const formData: FormData = new FormData();

    formData.append("address", address);
    formData.append("city", city);
    formData.append("pincode", pincode);
    //  formData.append('course', "asds");
    formData.append("state", state);

    // http://192.168.0.111:7000/api/v1/admin/almashine/listAllAlumni?page=1
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/updateAlumnusAddress?id=` +
          id,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  updateregister(
    id: any,
    name: any,
    reg_no: any,
    address: any,
    city: any,
    pincode: any,
    mobile_no: any,
    institution: any,
    branch: any,
    email: any,
    state: any,
    card: any,
    amount: any,
    photo: File
  ) {
    console.log(amount);
    const formData: FormData = new FormData();

    formData.append("alumni_id_image", photo);

    // formData.append('alumni_name', name);
    // formData.append('register_no', reg_no);
    // formData.append('address', address);
    // formData.append('city', city);
    // formData.append('pincode', pincode);
    // formData.append('phone_no', mobile_no);
    // formData.append('institution', institution);
    // formData.append('branch', branch);
    // //  formData.append('course', "asds");
    // formData.append('email', email);
    // formData.append('state', state);
    // formData.append('collect_card', card);
    // formData.append('amount', amount);

    // http://192.168.0.111:7000/api/v1/admin/almashine/listAllAlumni?page=1
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/updateAlumnusImage?id=` +
          id,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  updateregisterCoursera(
    id: any,
    name: any,
    reg_no: any,
    address: any,
    city: any,
    pincode: any,
    mobile_no: any,
    institution: any,
    branch: any,
    email: any,
    state: any,
    card: any,
    amount: any,
    photo: File
  ) {
    console.log(amount);
    const formData: FormData = new FormData();

    formData.append("alumni_id_image", photo);

    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/updateAlumnusImage?id=` +
          id,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  //for swimming

  ccevenue(
    billing_name: any,
    billing_email: any,
    billing_phone: any,
    billing_address: any,
    orderid: any,
    tid: any,
    amount: any,
    merchant_param1: any
  ) {
    var formData = {
      billing_name: billing_name,
      billing_email: billing_email,
      billing_phone: billing_phone,
      billing_address: billing_address,
      orderid: orderid,
      tid: tid,
      amount: amount,
      merchant_param1: merchant_param1,
    };

    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/ccavenue-alumni`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  verifypayment(
    razorpay_order_id: any,
    razorpay_payment_id: any,
    razorpay_signature: any,
    merchant_param: any,
    name: any,
    contact: any,
    amount: any,
    currency: any
  ) {
    var formData = {
      razorpay_order_id: razorpay_order_id,
      razorpay_payment_id: razorpay_payment_id,
      razorpay_signature: razorpay_signature,
      merchant_param: merchant_param,
      name: name,
      contact: contact,
      amount: amount,
      currency: currency,
    };
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/verify-payment`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }
}
